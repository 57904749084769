import './bootstrap';


console.log('app.js running');

window.modalOpen = function () {
    const elem = document.querySelector("#modalWindow");

    console.log('modalOpen');
    elem.classList.remove('modalWindowHide');
}

window.modalClose = function () {
    const elem = document.querySelector("#modalWindow");
    console.log('modalClose');
    elem.classList.add('modalWindowHide');
}

window.makeRandomAddress = function () {
    const elemName = document.querySelector("#newName");
    const elemEmail = document.querySelector("#newEmail");

    elemName.value = Math.random().toString(32).substring(2,8);
    elemEmail.value = Math.random().toString(32).substring(2,8) + '@example.com';
}

// -------
/***
const windDirPanel = document.getElementById('form_wind_dir');
const windVerPanel = document.getElementById('form_wind_ver');
const rainPanel = document.getElementById('form_rain');
const levelPanel = document.getElementById('form_levelrain');

const inputAlertType = document.getElementById('alert_type');

const observer = new MutationObserver((mutations) => {
     mutations.forEach((mutation) => {
         console.log('contain=' + mutation.target.classList.contains('active'));
         if( mutation.target.classList.contains('active')){
             //
            let attr = mutation.target.getAttribute('data-alertType');
             console.log('active = ' + attr);
            document.getElementById('alert_type').value = attr;
         }
     });
});

const config = {
    characterData: true,
    attributes: true,
    subtree: true
};

if(windDirPanel !== null){
    console.log('Start obserbation...');
    observer.observe(windDirPanel, config);
    observer.observe(windVerPanel, config);
    observer.observe(rainPanel, config);
    observer.observe(levelPanel, config);
}

***/
